import "./FAQs.css";
import Accordian, { AccordianItem } from "./Accordian";
import { faqContent } from "../../content";

const FAQs = () => {
  return (
    <section id="faqs">
      <div className="sectionHeading">
        <h2>{faqContent.title}</h2>
        <p>{faqContent.subtitle}</p>
      </div>

      <div className="flex flex-col items-center justify-center">
        <Accordian className="">
          {faqContent.faqData.map((faq) => (
            <AccordianItem key={faq.id} value={faq.id} trigger={faq.question}>
              {faq.answer}
            </AccordianItem>
          ))}
        </Accordian>
      </div>
    </section>
  );
};

export default FAQs;
