export const general = {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    dataProtection: "Your data is always protected with us. Read our <a href='/privacy-policy'>Privacy Policy</a>.",
    allFieldsError: "Oops! Looks like you missed a field. Please complete all fields.",
    heroNote: "*Discover the earning potential with our surveys! Average payouts are $3 per survey. Imagine earning an extra $300/month by completing just 5 surveys each day for 5 days a week.",
}

export const homeContent = {
    how: "How It Works",
    dataProtection: "Data Protection",
    faq: "FAQs",
    started: "Start earning cash today and sign up for FREE!",
}

export const heroContent = {
    heroTitle: "Start Earning Cash with Online Surveys!",
    heroSubtitle: "Earn up to $300 Monthly or $25 per Survey* - Make the Most of Your Spare Time!",
    heroList: [
        "Quick & easy sign-up process",
        "Personalized survey matches for maximum earnings",
        "Earn rewards for each survey you complete",
        "Limited spots - Join the community today!"
    ],
    signUpTitle: "Get Started and Earn Rewards",
    selectGender: "Select Your Gender *",
    heroNote: general.heroNote,
    signupFree: "JOIN FOR FREE",
    dataProtection: general.dataProtection,
    ageError: "Participants must be 18 or older.",
    allFieldsError: general.allFieldsError,
    months: general.months,
};

export const hero2Content = {
    males: "males",
    females: "females",
    dynamicMessages: {
        "greatNews": "Great news! We've matched survey companies seeking {gender} aged {age}."
    },
    subtitle: "Just a Few More Details to Kickstart Your Earning Journey!",
    boxTitle: "Enter Your Details & Begin Earning",
    emailError: "Please enter a valid email address.",
    allFieldsError: general.allFieldsError,
    fname: "First Name",
    lname: "Last Name",
    yes: "Yes",
    no: "No",
    consent: "Consent to daily emails for new opportunities from SurveyReel.",
    canUnsub: "Unsubscribe with ease any time.",
    cta: "SIGN UP & START EARNING",
    agree: "By signing up, you agree to our <a href='/terms'>Terms & Conditions</a> and acknowledge our <a href='/privacy-policy'>Privacy Policy</a>.",
    dataProtection: general.dataProtection,
    footnote: general.heroNote,
};

export const aboutContent = {
    whoWeAreTitle: "Who We are",
    whoWeAre: "At Survey Reel, we deliver America's top surveys directly to you. Our platform provides immediate access to a diverse array of surveys, valuing and rewarding your insights. Keep an eye out for new and engaging survey opportunities!", questions: "Questions? Feel free to visit our <a href='#faqs'> help page</a>",
    rightTitle: "Articles",
    dataProtection: "At Survey Reel, we treat your data with the utmost care. Upon signing up, rest assured your information is secure and used solely to update you on our latest offerings!",
    optOut: "Choose to opt-out from Survey Reel communications anytime. For assistance, visit our <a href='#faqs'>help page</a>."
};

export const howItWorks = {
    title: "How It Works",
    subtitle: "Earning is easy! Just follow these 3 simple steps:",
    stepOneTitle: "Sign Up For Free",
    stepOneSubtitle: "Quickly register using our form and get matched with leading survey companies. It's effortless and completely free!",
    stepTwoTitle: "Choose & Complete Surveys",
    stepTwoSubtitle: "We'll send regular survey invites your way. Seize every chance for maximized earnings.",
    stepThreeTitle: "Earn Rewards - Cash or Gift Cards",
    stepThreeSubtitle: "Receive your rewards as you prefer – be it cash or vouchers for your favorite stores!",
}

export const offersContent = {
    months: general.months,
    dynamicMessages: {
        "subtitle": "Great news! Survey panels seek new {gender} members aged under {age} to join by {formattedNextWeek}. Choose your panels – more selections mean higher earnings.",
    },
    popupTitle: "Additional Information Needed",
    popupDesc: "To complete your sign-up with selected survey companies, please provide your address. Start earning now!",
    postCode: "Postcode",
    enterCode: "Enter Your Code",
    continue: "CONTINUE",
    nextStepTitle: "NEXT: Choose Survey Panels to Join",
    tip: "PRO TIP: Opt for all and respond to email invites for peak earnings.",
    selectAll: "<span>SELECT ALL</span> & Potentially Earn $300/month",
    footnote: "* Based on completing 2-3 surveys daily (about an hour's work daily)",
    errorSelectOne: "Please select at least one offer",
    continue: "PROCEED TO FINAL STEP",
}

export const tasksContent = {
    months: general.months,
    subtitle: "All by answering simple questions for some of the world's biggest companies.",
    continue: "CONTINUE",
    title: "Earn extra income for your time... even with an existing&nbsp;job</span>",
    featuredTasks: "Featured Tasks",
    started: "Start earning cash today!",
}

export const lastStepContent = {
    "title": "FINAL STEP: Check Your Email Now",
    "subtitle": "Your first surveys await! Confirm your registration with each selected company via email to start earning. <br/><span>IMPORTANT: This step is crucial for payment eligibility.</span>",
    "stepsTitle": "What to Do Next",
    "stepsSub": "To begin earning, you need to:",
    "step1Title": "Check Your Emails Immediately",
    "step1Content": "Look for emails from the companies you chose.",
    "step2Title": "Confirm via Email Links",
    "step2Content": "Essential step! Confirmation links must be clicked for survey company acknowledgment.",
    "step3Title": "Don't Forget Your Spam/Junk Folder",
    "step3Content": "Activation emails may sometimes land there.",
    "rewardsTitle": "Enjoy Rewards From"
}

export const brandsContent = {
    title: "Earn Rewards from these major brands",
}

export const faqContent = {
    title: "FAQs",
    subtitle: "Your Questions Answered",
    faqData: [
        {
            id: "1",
            question: "How does it work?",
            answer: "Survey Reel connects you with companies seeking feedback. We've curated top survey opportunities for you. Simply sign up, choose surveys, and confirm your participation via email. After completing surveys, enjoy claiming your rewards. Remember, new opportunities are always popping up, so keep an eye out!"
        },
        {
            id: "2",
            question: "How much does it cost?",
            answer: "Absolutely nothing! Survey Reel is a free service with no hidden charges. Enjoy complimentary access to our survey selection, with every survey we feature being free to join."
        },
        {
            id: "3",
            question: "How do I start earning?",
            answer: "Getting started is a breeze! Just sign up through our form to explore survey options. Select surveys, click YES, and watch for confirmation emails from the companies. Don’t forget to check your spam/junk folder occasionally. Once confirmed, you’ll receive surveys to complete and start earning rewards!"
        }
    ],
}

export const startedContent = {
    cta: "START NOW",
}

export const footerContent = {
    copy: "© Survey Reel. All Rights Reserved • <a href='/privacy-policy'>Privacy Policy</a> • <a href='/terms'>Terms and Conditions</a>",
    notice: "SurveyReel trademarks including SurveyReel® and the SurveyReel logo are the property of PixelCraft LLC; all rights reserved. Other trademarks appearing on this site are property of their respective owners, which do not endorse and are not affiliated with SurveyReel or its promotions.",
}

export default heroContent;