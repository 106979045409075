import React from "react";
import styles from "./TaskListBox.module.css"; // Assuming you are using CSS modules
import LocationIcon from "../../assets/location-dot.svg";
import DollarIcon from "../../assets/dollar.svg";
import ArrowIcon from "../../assets/arrow-up.svg";

const TaskListBox = ({ data }) => {
  const { img, heading, name, location, payout, path } = data;
  const queryString = window.location.search;

  const constructUrlWithPathParams = (path) => {
    const hasQueryString = path.includes('?');
    return hasQueryString ? `${path}&${queryString.substring(1)}` : `${path}${queryString}`;
  };

  const finalPath = constructUrlWithPathParams(data.path);


  return (
    <a href={finalPath} target="_blank" rel="noopener noreferrer" className={styles.listBoxLink}>
      <div className={styles.listBox}>
        <div className={styles.listFlex}>
          <div className={styles.listColumn}>
            <div className={styles.listImg}>
              <img src={img} alt="" />
            </div>
          </div>
          <div className={styles.listColumn}>
            <div className={styles.listContent}>
              <h2>{name} - {heading}</h2>
            </div>
            <div className={styles.listSubContent}>
              <span className={styles.pathLink}>{name}</span><span className={styles.arrow}><img src={ArrowIcon} alt="ArrowUp" className={styles.icon} /></span>
            </div>
          </div>
          <div className={styles.listColumn}>
            <div className={styles.iconWrapper}>
              <img src={LocationIcon} alt="Location" className={styles.icon} />
              <span>Location</span>
            </div>
            <div className={styles.listContent}>
              <h2>{location}</h2>
            </div>
          </div>
          <div className={styles.listColumn}>
            <div className={styles.iconWrapper}>
              <img src={DollarIcon} alt="Location" className={styles.icon} />
              <span>Potential Payout</span>
            </div>
            <div className={styles.listContent}>
              <h2>{payout}</h2>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default TaskListBox;
