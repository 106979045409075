import "./Hero.css";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormDataContext from "../../contexts/FormDataContext";
import { hero2Content } from "../../content";
import formatMessage from "../../utils/Utils";
import { addUserMetadata } from "../FirebaseService";

const Hero2 = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [consent, setConsent] = useState(true); // Changed from yesNo to consent with boolean type
  const { formData } = useContext(FormDataContext);
  const { gender, year, month, day } = formData;
  const currentYear = new Date().getFullYear();
  const age = currentYear - year;
  const genderText = gender === 'male' ? hero2Content.males : hero2Content.females;
  const greatNews = formatMessage(hero2Content.dynamicMessages.greatNews, { gender: genderText, age: age });
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const signup = async (e) => {
    e.preventDefault();

    if (!email || !isValidEmail(email)) {
      setEmailError(hero2Content.emailError);
      return;
    } else {
      setEmailError("");
    }

    if (firstName && lastName && consent !== false) {
      try {
        await addUserMetadata({
          'email': email,
          'fname': firstName,
          'lname': lastName,
          'accept': consent ? "yes" : "no",
          'dob': `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
          'gender': gender,
        });
        navigate("/offers");
      }
      catch (error) {
        console.error('Error during signup:', error);
        // setErrorMessage('Failed to sign up. Please try again.');
      }
    } else {
      console.log(hero2Content.allFieldsError);
    }
  }

  return (
    <div className="hero" id="hero">
      <div className="firstHome">
        <div className="heroContentContainer">
          <div className="hero_content">
            <h1 id="heroText">{greatNews}</h1>
            <h2>{hero2Content.subtitle}</h2>
          </div>
        </div>
        <div className="signupFormContainer">
          <form className="signup" onSubmit={signup}>
            <h2>{hero2Content.boxTitle}</h2>
            <div className="inputBox">
              <div className="email">
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  required
                />
              </div>
              <div className="names">
                <input
                  type="text"
                  placeholder={hero2Content.fname}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder={hero2Content.lname}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="selectOne">
                <p>{hero2Content.consent}</p>
                <div>
                  <input
                    type="checkbox"
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                  />
                </div>
              </div>
              <p className="unsubscribe">{hero2Content.canUnsub}</p>
            </div>
            {emailError && <p className="error-message">{emailError}</p>}
            <button className="btn-red" type="submit">
              {hero2Content.cta}
            </button>
            <div className="sbottom">
              <p dangerouslySetInnerHTML={{ __html: hero2Content.agree }}></p>
              <span className="signup-notice" dangerouslySetInnerHTML={{ __html: hero2Content.dataProtection }}></span>
            </div>
          </form>
          <p className="heroPara">
            {hero2Content.footnote}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero2;