import "./Hero.css";
import Check from "../../assets/check-solid.svg";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { heroContent } from "../../content";
import FormDataContext from "../../contexts/FormDataContext";
import ReactGA from 'react-ga4';

const Hero = () => {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [gender, setGender] = useState("");
  const { setFormData } = useContext(FormDataContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const calculateAge = (day, month, year) => {
    const birthday = new Date(year, month - 1, day);
    const today = new Date();
    const age = today.getFullYear() - birthday.getFullYear();
    const m = today.getMonth() - birthday.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      return age - 1;
    }
    return age;
  };

  function signup(e) {
    e.preventDefault();
    const userAge = calculateAge(day, month, year);

    if (userAge < 18) {
      setErrorMessage(heroContent.ageError);
      ReactGA.event({
        category: 'Hero',
        action: 'Pressed',
        label: 'error'
      });
      return;
    } else {
      setErrorMessage("");
    }
    if (day && month && year && gender) {
      setFormData({ day, month, year, gender });
      navigate("/signup", { state: { day, month, year, gender } });
      ReactGA.event({
        category: 'Hero',
        action: 'Pressed',
        label: 'success'
      });
    } else {
      console.log(heroContent.allFieldsError);
      ReactGA.event({
        category: 'Hero',
        action: 'Pressed',
        label: 'missing fields'
      });
    }
  }

  const getMonthOptions = () => {
    const months = heroContent.months;
    return months.map((month, index) => (
      <option key={index} value={index + 1}>{month}</option>
    ));
  };

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = currentYear - 10; i >= currentYear - 100; i--) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  };

  const getDayOptions = () => {
    let days = [];
    for (let i = 1; i <= 31; i++) {
      days.push(<option key={i} value={i}>{i}</option>);
    }
    return days;
  };

  return (
    <div className="hero" id="hero">
      <div className="firstHome">
        <div className="heroContentContainer">
          <div className="hero_content">
            <h1 id="heroText">{heroContent.heroTitle}</h1>
            <h2>{heroContent.heroSubtitle}</h2>
            <ul>
              {heroContent.heroList.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="signupFormContainer">
          <form className="signup" onSubmit={signup}>
            <h2>{heroContent.signUpTitle}</h2>
            <p>{heroContent.selectGender}</p>
            <div className="gender">
              <div className="gender-box">
                <label className="custom-radio" required>
                  <input
                    type="radio"
                    name="gender"
                    required
                    value="male"
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <span className="radio-btn male">
                    <img src={Check} alt="check" aria-readonly />
                    <div className="backOfCheck"></div>
                    <div className="gender-pic">
                    </div>
                  </span>
                  <span className="gender-type">Male</span>
                </label>
              </div>
              <div className="gender-box">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="gender"
                    required
                    value="female"
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <span className="radio-btn female">
                    <img src={Check} alt="check" />
                    <div className="backOfCheck"></div>
                    <div className="gender-pic">
                    </div>
                  </span>
                  <span className="gender-type">Female</span>
                </label>
              </div>
            </div>
            <div className="dob">
              <span>Date of Birth *</span>
              <div className="dob-inputs">
                <select name="day" required onChange={(e) => setDay(e.target.value)}>
                  <option value="">Day</option>
                  {getDayOptions()}
                </select>
                <select name="month" required onChange={(e) => setMonth(e.target.value)}>
                  <option value="">Month</option>
                  {getMonthOptions()}
                </select>
                <select name="year" required onChange={(e) => setYear(e.target.value)}>
                  <option value="">Year</option>
                  {getYearOptions()}
                </select>
              </div>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button className="btn-red" type="submit">{heroContent.signupFree}</button>
            <span className="signup-notice" dangerouslySetInnerHTML={{ __html: heroContent.dataProtection }}></span>
          </form>
          <p className="heroPara">{heroContent.heroNote}</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
