import Survey from "../../assets/feature-surveys.svg";
import Signup from "../../assets/feature-signup.svg";
import Rewards from "../../assets/feature-rewards.svg";
import "./HowItWorks.css";
import { howItWorks } from "../../content";

const HowItWorks = () => {
  return (
    <section id="howItWorks">
      <div className="workingWrapper">
        <div className="sectionHeading">
          <h2>{howItWorks.title}</h2>
          <p>{howItWorks.subtitle}</p>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div className="workingBox">
              <div class="rank">1</div>
              <img src={Signup} className="sinimg" alt="" />
              <div class="title">
                <h3>{howItWorks.stepOneTitle}</h3>
              </div>
              <p>
                {howItWorks.stepOneSubtitle}
              </p>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="workingBox">
              <div class="rank">2</div>
              <img src={Survey} alt="" />
              <div class="title">
                <h3>{howItWorks.stepTwoTitle}</h3>
              </div>
              <p>
                {howItWorks.stepTwoSubtitle}
              </p>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="workingBox">
              <div class="rank">3</div>
              <img src={Rewards} alt="" />
              <div class="title">
                <h3>{howItWorks.stepThreeTitle}
                </h3>
              </div>
              <p>
                {howItWorks.stepThreeSubtitle}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
