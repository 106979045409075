import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import "./Tasks.css";
import Footer from "../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { tasksContent, homeContent } from "../content";
import TaskListBox from "../components/TaskListBox/TaskListBox";
import Clients from "../components/Clients/Clients.jsx";
import About from "../components/About/About.jsx";
import FAQs from "../components/Faq/FAQs.jsx";
import tasksData from "../data/tasks.json";
import Started from "../components/Started/Started.jsx";

const Tasks = () => {
  const navigate = useNavigate();

  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchTasks = () => {
      try {
        setTasks(tasksData);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, []);

  return (
    <>
      <Navbar list={[{ text: "Tasks", id: "#tasks" }, { text: "About Us", id: "#about" }, { text: homeContent.faq, id: "#faqs" }]} />
      <div id="navbar"></div>
      <section className="taskHero">
        <div className="taskHeroContent">
          <h1><p dangerouslySetInnerHTML={{ __html: tasksContent.title }}></p></h1>
          <h2>{tasksContent.subtitle}</h2>
        </div>
      </section>
      <section className="taskList">
        <div className="listHeading">
          <h2>{tasksContent.featuredTasks}</h2>
        </div>
        <div className="taskListBoxes">
          {tasks.map((data, index) => (
            <TaskListBox
              key={index}
              data={data}
            />
          ))}
        </div>
      </section>
      <Clients />
      <About />
      <FAQs />
      <Started text={tasksContent.started} />
      <Footer showLogo={true} />
    </>
  );
};

export default Tasks;
