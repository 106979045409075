import React from "react";
import "./LastStep.css";
import Navbar from "../components/Navbar/Navbar";
import amazon from "../assets/logo-amazon.svg";
import paypal from "../assets/logo-paypal.svg";
import walmart from "../assets/logo-walmart.svg";
import target from "../assets/logo-target.svg";
import visa from "../assets/logo-visa.svg";

import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { lastStepContent } from "../content";

const LastStep = () => {
  const navigate = useNavigate()
  return (
    <>
      <Navbar />
      <div id="navbar"></div>
      <section className="lastHero">
        <div className="lastHeroContent">
          <h1>{lastStepContent.title}</h1>
          <h2 dangerouslySetInnerHTML={{ __html: lastStepContent.subtitle }}></h2>
          {/* <button>GO TO YOUR INBOX</button> */}
        </div>
      </section>
      <section className="finalStep">
        <div>
          <div className="stepHeading">
            <h2>{lastStepContent.stepsTitle}</h2>
            <span>{lastStepContent.stepsSub}</span>
          </div>

          <div className="stepLists">
            <div className="step">
              <div className="number">1</div>
              <div className="stepContent">
                <h2>{lastStepContent.step1Title}</h2>
                <p>{lastStepContent.step1Content}</p>
              </div>
            </div>
            <div className="step">
              <div className="number">2</div>
              <div className="stepContent">
                <h2>{lastStepContent.step2Title}</h2>
                <p>{lastStepContent.step2Content}</p>
              </div>
            </div>
            <div className="step">
              <div className="number">3</div>
              <div className="stepContent">
                <h2>{lastStepContent.step3Title}</h2>
                <p>{lastStepContent.step3Content}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="finalClients">
        <div className="finalClients">
          <div className="stepHeading">
            <h2>{lastStepContent.rewardsTitle}</h2>
          </div>
          <div className="clientBox">
            <div className="amazon">
              <img src={amazon} alt="" />
            </div>
            <div className="visa">
              <img src={visa} alt="" className="visa" />
            </div>
            <div className="target">
              <img src={target} alt="" className="target" />
            </div>
            <div className="paypal">
              <img src={paypal} alt="" className="paypal" />
            </div>
            <div className="walmart">
              <img src={walmart} alt="" className="walmart" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LastStep;
