import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Offer from "./pages/Offer";
import LastStep from "./pages/LastStep";
import Layout from "./Layout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Tasks from "./pages/Tasks";
import ExternalRedirect from "./utils/ExternalRedirect";
import redirects from "./data/tasks.json";
import Article from "./components/Article/Article";
import articles from "./data/blog.json";

const externalRedirectRoutes = redirects.map(redirect => ({
    path: redirect.path,
    element: <ExternalRedirect to={redirect.url} />,
}));

const externalArticleRoutes = articles.map(article => ({
    path: article.path,
    element: <Article heading={article.heading} subheading={article.subheading} article={article.article} />,
}));

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            // {
            //     path: ":step?",
            //     element: <Home />,
            // },
            {
                path: "",
                element: <Tasks />,
            },
            {
                path: "tasks",
                element: <Tasks />
            },
            {
                path: "offers",
                element: <Tasks />,
            },
            {
                path: "laststep",
                element: <LastStep />,
            },
            {
                path: "privacy-policy",
                element: <PrivacyPolicy />
            },
            {
                path: "terms",
                element: <Terms />
            },
            {
                path: "email/:step?",
                element: <Home />,
            },
            ...externalRedirectRoutes,
            ...externalArticleRoutes,
            {
                path: "*",
                element: <Tasks />,
            }
        ],
    },
]);

export default router;
