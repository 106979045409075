import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore, doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCJlhZuh3DT1Ls67JkJMpLPDfkwuH0d__c",
  authDomain: "surveyreel-proje-1704630450359.firebaseapp.com",
  projectId: "surveyreel-proje-1704630450359",
  messagingSenderId: "406309308269",
  appId: "1:406309308269:web:0585fc7b729395780c3c64",
  measurementId: "G-06PEYTKQJF"
};

const firebaseapp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseapp);

const firebaseSignInAnonymously = () => {
  return signInAnonymously(auth);
};

const addUserMetadata = async (metadata) => {
  try {
    const firestore = getFirestore(firebaseapp);
    const userDocRef = doc(firestore, "users", auth.currentUser.uid);
    await setDoc(userDocRef, { ...metadata, createdAt: serverTimestamp(), updatedAt: serverTimestamp() }, { merge: true });
  }
  catch (error) {
    console.error("Error adding/updating user metadata:", error);
    throw error;
  }
};

export { firebaseapp, firebaseSignInAnonymously, addUserMetadata };
