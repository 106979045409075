import amazon from "../../assets/logo-amazon.svg";
import walmart from "../../assets/logo-walmart.svg";
import paypal from "../../assets/logo-paypal.svg";
import visa from "../../assets/logo-visa.svg";
import target from "../../assets/logo-target.svg"

import "./Clients.css";
import { brandsContent } from "../../content";

const Clients = () => {
  return (
    <section id="clients">
      <h2>{brandsContent.title}</h2>

      <div className="clientBox">
        <div className="amazon">
          <img src={amazon} alt="" />
        </div>
        <div className="visa">
          <img src={visa} alt="" className="visa" />
        </div>
        <div className="target">
          <img src={target} alt="" className="target" />
        </div>
        <div className="paypal">
          <img src={paypal} alt="" className="paypal" />
        </div>
        <div className="walmart">
          <img src={walmart} alt="" className="walmart" />
        </div>
      </div>
    </section>
  );
};

export default Clients;
