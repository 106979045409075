import "./Started.css";
import { startedContent } from '../../content';

const Started = ({ text }) => {
  return (
    <section id="started">
      <div>
        <h1>{text}</h1>
        <a href="./#top">{startedContent.cta}</a>
      </div>
    </section>
  );
};

export default Started;
