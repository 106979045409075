import React from 'react';
import './Terms.css';
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

const Terms = () => {
    return (
        <>
            <Navbar />
            <div class="page-wrapper">
                <div class="intro">
                    <h1>TERMS AND CONDITIONS</h1>
                </div>
                <div class="jumbotron jumbotron-white">
                    <div>
                        <div>
                            <div>

                                <div class="d10 doc-content">
                                    <h1 class="d11" id="h.tev5gfo7rjvu"><span class="d9">TERMS AND CONDITIONS</span></h1>
                                    <p class="d1"><span class="d0">PLEASE CAREFULLY READ THESE TERMS AND CONDITIONS BEFORE USING THIS WEBSITE. This
                                        website (the &ldquo;Website&rdquo;) is owned and operated by PixelCraft LLC (&ldquo;Company&rdquo;,
                                        &ldquo;us&rdquo;, &ldquo;we&rdquo; or &ldquo;our&rdquo;). These Terms and Conditions and any amendments or
                                        supplements to it, together with our Privacy Policy (collectively, the &ldquo;Agreement&rdquo;) form a
                                        legally binding agreement between you and Company. This Agreement governs your access to and use of any
                                        Company Website, your use or attempted use of our services (collectively, &ldquo;Your Use&rdquo;).</span>
                                    </p>
                                    <p class="d1"><span class="d0">Your use of the Website shall be deemed to constitute your consent to be bound by
                                        this Agreement and shall be enforceable in the same way as if you had signed this Agreement. IF YOU DO NOT
                                        AGREE TO BE BOUND BY THIS AGREEMENT, PLEASE DO NOT USE THE WEBSITE.</span></p>
                                    <p class="d1"><span class="d0">THIS AGREEMENT INCLUDES A MANDATORY ARBITRATION AGREEMENT, WHICH MEANS THAT YOU AGREE
                                        TO SUBMIT ANY CLAIM (DEFINED IN SECTION 13) TO BINDING INDIVIDUAL ARBITRATION RATHER THAN PROCEEDING IN
                                        COURT. IF YOU WANT TO OPT-OUT OF THIS MANDATORY ARBITRATION AGREEMENT, SECTION 13 BELOW DESCRIBES THE
                                        PROCEDURES YOU MUST FOLLOW TO DO SO. THE ARBITRATION AGREEMENT ALSO INCLUDES A CLASS ACTION WAIVER, WHICH
                                        MEANS THAT YOU AGREE TO PROCEED WITH ANY CLAIM INDIVIDUALLY AND NOT AS PART OF A CLASS ACTION.</span></p>
                                    <h2 class="d3" id="h.f5g6e9gfl64n"><span class="d2">1. Eligibility</span></h2>
                                    <p class="d1"><span class="d0">In order to use the Website, you must be 18 years or older and have the power to
                                        enter into a binding contract with us and not be barred from doing so under any applicable laws. The Website
                                        is not intended for children under the age of 13 and no person under the age of 13 may use the
                                        Website.</span></p>
                                    <h2 class="d3" id="h.yoca6s132d8q"><span class="d2">2. User Conduct</span></h2>
                                    <p class="d1"><span class="d0">You must only use the Website for lawful purposes, and you must not use it in a way
                                        that infringes the rights of anyone else or that restricts or inhibits anyone else&rsquo;s enjoyment of the
                                        Website. You may not without our prior written consent:</span></p>
                                    <ul class="d7 lst-kix_sa88lnacwndx-0 start">
                                        <li class="d1 d4 li-bullet-0"><span class="d0">a. copy, reproduce, rent, lease, loan or sell content retrieved
                                            from the Website;</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">b. modify, distribute, or re-post any content on the Website for
                                            any purpose; or</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">c. use the content of the Website for any commercial exploitation
                                            whatsoever</span></li>
                                    </ul>
                                    <p class="d1"><span class="d0">In using the Website, you further agree:</span></p>
                                    <ul class="d7 lst-kix_646qxncye0q7-0 start">
                                        <li class="d1 d4 li-bullet-0"><span class="d0">a. not to disrupt or interfere with the security of, or otherwise
                                            abuse, the Website, or any services, system resources, accounts, servers, or networks connected to or
                                            accessible through the Website or an affiliated or linked Website;</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">b. not to disrupt or interfere with any other user&rsquo;s
                                            enjoyment of the Website or affiliated or linked Website;</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">c. not to upload, post, or otherwise transmit through or on the
                                            Website any viruses or other harmful, disruptive, or destructive files;</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">d. not to use, frame, or utilize framing techniques to enclose
                                            any Company trademark, logo, or other proprietary information (including the images found on the
                                            Website, the content of any text, or the layout/design of any page or form contained on a Website page)
                                            without Company&rsquo;s express written consent;</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">e. not to reverse engineer, or create derivative works based on
                                            the Website or any content (including, without limitation, any software) available through the
                                            Website.</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">f. not to use meta tags or any other &ldquo;hidden text&rdquo;
                                            utilizing a Company name, trademark, or product name without Company&rsquo;s express written
                                            consent;</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">g. not to deeplink to the Website without Company&rsquo;s express
                                            written consent;</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">h. not to create or use a false identity on the Website;</span>
                                        </li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">i. not to collect or store personal data about others;</span>
                                        </li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">j. not to attempt to obtain unauthorized access to the Website or
                                            portions of the Website that are restricted from general access;</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">k. if applicable, not to post any material that is knowingly
                                            false and/or defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, profane, sexually
                                            oriented, threatening, invasive of a person&rsquo;s privacy, or is otherwise in violation of any law.
                                            You further agree not to post any copyrighted material unless the copyright is owned by you; and</span>
                                        </li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">l. to comply with all applicable laws regarding your use of the
                                            Website.</span></li>
                                    </ul>
                                    <h2 class="d3" id="h.bs1lqdtl1lq9"><span class="d2">3. Protection of Intellectual Property Rights and License</span>
                                    </h2>
                                    <p class="d1"><span class="d0">You acknowledge that content available through the Website, including, without
                                        limitation, content in the form of text, graphics, software, music, sound, photographs, and videos, and
                                        content provided by suppliers, sponsors, or third-party advertisers (&ldquo;Intellectual Property
                                        Rights&rdquo;), is protected by copyright, trademarks, patents, or other proprietary rights and laws. Except
                                        as expressly provided in this Agreement, nothing contained in this Agreement or on the Website shall be
                                        construed as conferring any other license or right, expressly, by implication, by estoppel, or otherwise
                                        under any of Company&rsquo;s or a third party&rsquo;s Intellectual Property Rights. Any rights not expressly
                                        granted herein are reserved.</span></p>
                                    <h2 class="d3" id="h.3oene5xgkogb"><span class="d2">4. Disclaimer Regarding Information Provided on the
                                        Website</span></h2>
                                    <p class="d1"><span class="d0">COMPANY AND ITS AGENTS ASSUME NO RESPONSIBILITY FOR ANY CONSEQUENCE RELATING DIRECTLY
                                        OR INDIRECTLY TO ANY ACTION OR INACTION YOU TAKE BASED ON THE INFORMATION, SERVICES, OR OTHER MATERIAL ON
                                        THE WEBSITE. WHILE COMPANY STRIVES TO KEEP THE INFORMATION ON THE WEBSITE ACCURATE, COMPLETE, AND
                                        UP-TO-DATE, COMPANY CANNOT GUARANTEE, AND WILL NOT BE RESPONSIBLE FOR, ANY DAMAGE OR LOSS RELATED TO THE
                                        ACCURACY, COMPLETENESS, OR TIMELINESS OF THE INFORMATION ON THE WEBSITE.</span></p>
                                    <h2 class="d3" id="h.hwmhi4bo7vf5"><span class="d2">5. Disclaimer of Warranties With Respect to Use of the
                                        Website</span></h2>
                                    <p class="d1"><span class="d0">THE WEBSITE IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo;
                                        BASIS. EXCEPT AS SPECIFICALLY PROVIDED HEREIN, TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
                                        COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                                        LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                                        COMPANY DOES NOT MAKE ANY WARRANTY THAT THE WEBSITE WILL MEET YOUR REQUIREMENTS, OR THAT ACCESS TO THE
                                        WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, OR THAT DEFECTS, IF ANY, WILL BE CORRECTED.
                                        COMPANY MAKES NO WARRANTIES AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITE OR AS TO THE
                                        ACCURACY, QUALITY, OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE WEBSITE. NEW JERSEY DOES NOT ALLOW
                                        THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR
                                        ANY RESTRICTIONS OR LIMITATIONS REGARDING THE EXCLUSION OF IMPLIED WARRANTIES.</span></p>
                                    <p class="d1"><span class="d0">YOU UNDERSTAND AND AGREE THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE
                                        OBTAINED THROUGH THE USE OF THE WEBSITE IS USED AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR
                                        ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR
                                        DATA. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM COMPANY OR THROUGH THE WEBSITE
                                        SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.</span></p>
                                    <h2 class="d3" id="h.afvppkq99o47"><span class="d2">6. Links to Other Websites That Company Does Not Control</span>
                                    </h2>
                                    <p class="d1"><span class="d0">The Website may provide links to other websites and/or resources, including
                                        advertisers, over which Company has no control. These links are provided solely as a convenience to users
                                        and should not be construed as an endorsement by Company of content, items, or services on those third-party
                                        websites. You access, view and use such website links, including the content, items or services on those
                                        websites, solely at your own risk. Company makes no representations or warranties with respect to the
                                        content, ownership, or legality of any such linked websites. You agree that Company has no responsibility or
                                        liability for the availability of such external websites or resources, or for the content, advertising,
                                        products, or other materials available through such websites or resources. At the moment when you leave the
                                        Website via a link to another website, you will be subject to the Privacy Policy and the Terms of Use of
                                        such other website.</span></p>
                                    <h2 class="d3" id="h.yv8oe35mna8o"><span class="d2">7. Electronic Communications, Signatures and Agreements</span>
                                    </h2>
                                    <p class="d1"><span class="d0">The information communicated on the Website constitutes an electronic communication.
                                        When you communicate with Company through the Website or via other forms of electronic media, such as
                                        e-mail, you are communicating with Company electronically. You agree that Company may communicate
                                        electronically with you and that such communications, as well as notices, disclosures, agreements, and other
                                        communications that Company provides to you electronically, are equivalent to communications in writing and
                                        shall have the same force and effect as if they were in writing and signed by the party sending the
                                        communication (e.g., by Company or you).</span></p>
                                    <h2 class="d3" id="h.qrb3vpuvuta8"><span class="d2">8. Communications with Company</span></h2>
                                    <p class="d1"><span class="d0">You acknowledge that telephone calls to or from Company may be monitored and recorded
                                        and you agree to such monitoring and recording.</span></p>
                                    <p class="d1"><span class="d0">You verify that any contact information provided to Company, including, but not
                                        limited to, your mailing address, shipping address, e-mail address, residential phone number, and mobile
                                        phone number is true and accurate. You further verify that you are the telephone subscriber and/or that you
                                        own any telephone numbers that you provide to Company. You acknowledge that by voluntarily providing your
                                        telephone numbers to Company, you expressly agree to be contacted at the telephone numbers you
                                        provide.</span></p>
                                    <p class="d1"><span class="d0">You consent to receive e-mails, pre-recorded voice messages and/or autodialed calls
                                        (including text messages) by or on behalf of Company relating to this Agreement, any transaction with
                                        Company, and promotions regarding Company services. These communications may be made by or on behalf of
                                        Company, even if your phone number is registered on any state or federal Do Not Call list. You acknowledge
                                        that you may incur a charge for these calls by your telephone carrier and that Company will not be
                                        responsible for these charges.</span></p>
                                    <p class="d1"><span class="d0">Company may obtain, and you expressly agree to be contacted at, e-mail addresses,
                                        mailing addresses and phone numbers provided by you directly or obtained through other lawful means. You
                                        agree to provide Company notice within 30 days of any change to your contact information by writing to
                                        PixelCraft LLC, 16192 COASTAL HWY, LEWES, Sussex, DE, 19958 or emailing . Your consent to this
                                        communications provision is not required to make any purchase with Company.</span></p>
                                    <h2 class="d3" id="h.snoaee9r0ae8"><span class="d2">9. International Users</span></h2>
                                    <p class="d1"><span class="d0">Your Use is controlled, operated and administered by Company from our offices within
                                        the USA. If you access the Website from a location outside the USA, you are responsible for compliance with
                                        all local laws. You agree that you will not use the Website any country or in any manner prohibited by any
                                        applicable laws, restrictions or regulations.</span></p>
                                    <h2 class="d3" id="h.eppt3ou7a8lf"><span class="d2">10. Limitation on Company&rsquo;s Liability</span></h2>
                                    <p class="d1"><span class="d0">YOU EXPRESSLY UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES WILL COMPANY, ITS
                                        SUPPLIERS, PARENTS, SUBSIDIARIES, REPRESENTATIVES, AFFILIATES OR AGENTS BE LIABLE FOR INDIRECT, SPECIAL,
                                        INCIDENTAL, OR CONSEQUENTIAL DAMAGES. THIS INCLUDES WITHOUT LIMITATION, ANY LOSS OF USE, LOSS OF PROFITS,
                                        LOSS OF DATA, LOSS OF GOODWILL, COST OF PROCUREMENT OF SUBSTITUTE SERVICES, OR ANY OTHER INDIRECT, SPECIAL,
                                        INCIDENTAL, OR CONSEQUENTIAL DAMAGES. THIS APPLIES REGARDLESS OF THE MANNER IN WHICH DAMAGES ARE CAUSED, AND
                                        ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY)
                                        OR OTHERWISE RESULTING FROM (1) THE USE OF, OR THE INABILITY TO USE, THE WEBSITE; OR (@) THE COST OF
                                        PROCUREMENT OF SUBSTITUTE SERVICES.</span></p>
                                    <h2 class="d3" id="h.a78uj044zpmi"><span class="d2">11. Indemnification</span></h2>
                                    <p class="d1"><span class="d0">You agree to indemnify and hold harmless Company, its directors, officers, employees,
                                        agents, co-branders, suppliers, subsidiaries, parents, and affiliates, from and against any and all
                                        liability, losses, claims, demands, disputes, damages, and costs of any kind, including, without limitation,
                                        reasonable attorneys&rsquo; fees and costs of litigation resulting from or in any way connected with (i)
                                        your use of the Website; (ii) information you submit or transmit through the Website; (iii) privacy, tort or
                                        other claims (e.g., claims under the Federal Telephone Consumer Protection Act or its state law equivalent)
                                        relating to the provision of personal information (e.g., telephone number) to Company that is not owned by
                                        you, in contravention of this Agreement; and/or (iv) your breach of this Agreement.</span></p>
                                    <h2 class="d3" id="h.f3xe8eo2vn6z"><span class="d2">12. Termination of Website</span></h2>
                                    <p class="d1"><span class="d0">You agree that Company may, in its sole discretion, and at any time, terminate or
                                        suspend its operation of the Website or your use of the Website, without prior notice to you, for any reason
                                        that Company, in its sole discretion, deems appropriate. You further agree that Company will not be liable
                                        to you or to any third party for the consequences of such termination or suspension. In the event of any
                                        termination of your use of or access to the Website, you agree that the provisions of the Agreement
                                        regarding protection of intellectual property rights and license, indemnification, disclaimer regarding
                                        information provided on the website, disclaimer of warranties with respect to use of the website, limitation
                                        on Company&rsquo;s liability, and pre-dispute, mandatory binding arbitration, and class action waiver shall
                                        survive any such termination.</span></p>
                                    <h2 class="d3" id="h.yxlnbgu0sk8p"><span class="d2">13. Pre-Dispute, Mandatory Binding Arbitration, and Class Action
                                        Waiver</span></h2>
                                    <p class="d1"><span class="d0">PLEASE READ THIS ARBITRATION PROVISION CAREFULLY TO UNDERSTAND YOUR RIGHTS. YOU AGREE
                                        THAT ANY CLAIM THAT YOU MAY HAVE IN THE FUTURE MUST BE RESOLVED THROUGH BINDING ARBITRATION. YOU ACKNOWLEDGE
                                        AND AGREE THAT YOU ARE WAIVING THE RIGHT TO A TRIAL BY JURY. THE RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO
                                        COURT, SUCH AS DISCOVERY OR THE RIGHT TO APPEAL, MAY BE MORE LIMITED OR MAY NOT EXIST.</span></p>
                                    <p class="d1"><span class="d0">YOU AGREE THAT YOU MAY ONLY BRING A CLAIM IN YOUR INDIVIDUAL CAPACITY, AND NOT AS A
                                        PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU FURTHER AGREE THAT THE
                                        ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OR CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE
                                        OR CLASS PROCEEDING.</span></p>
                                    <p class="d1"><span class="d5">Generally</span><span class="d0">. Arbitration is a method of resolving a Claim
                                        without filing a lawsuit. &ldquo;Claim&rdquo; means any dispute between you, Company, and/or any involved
                                        third party relating to your account, Your Use (defined here), your relationship with Company, or these
                                        Terms and Conditions. This includes any and all claims that relate in any way to your use of the services,
                                        your attempted use of the services, and any act or omission by Company or any third party related to your
                                        use or attempted use of the services. You, Company, or any involved third party may pursue a Claim. Company
                                        agrees to binding arbitration should it have any Claims against you. Likewise, you agree to binding
                                        arbitration should you have any Claims against Company. By agreeing to arbitrate, you waive the right to go
                                        to court and agree instead to submit any Claims to binding arbitration. This arbitration provision sets
                                        forth the terms and conditions of our agreement to binding arbitration and is governed by and enforceable
                                        under the Federal Arbitration Act (the &ldquo;FAA&rdquo;), 9 U.S.C. &sect;&sect; 1-16, as amended.</span>
                                    </p>
                                    <p class="d1"><span class="d0">Exceptions to Binding Arbitration. As an exception to binding arbitration, you and
                                        Company both retain the right to pursue, in a small claims court, any claim that is within that
                                        court&rsquo;s jurisdiction and proceeds on an individual (non-class) basis. Company will not demand
                                        arbitration in connection with any individual claim that you properly file and pursue in a small claims
                                        court, so long as the claim is pending only in that court. Binding arbitration also does not apply to
                                        disputes concerning trade secret misappropriation, patent infringement, copyright infringement or misuse, or
                                        trademark infringement or dilution.</span></p>
                                    <p class="d1"><span class="d5">Mandatory Pre-Dispute Procedures</span><span class="d0">. You acknowledge and agree
                                        that before initiating any Claim (subject to the exceptions above) against Company, you will first give us
                                        an opportunity to resolve your problem or dispute. This includes sending a written description of your
                                        problem or dispute to us, including, but not limited to, information or representations related to our
                                        services and upon which you rely. You may send the written description by PixelCraft LLC, 16192 COASTAL HWY,
                                        LEWES, Sussex, DE, 19958. You agree to negotiate with Company in good faith about your problem or dispute.
                                        If for some reason your problem or dispute is not resolved to your satisfaction within 60 days after
                                        Company&rsquo;s receipt of your written dispute, you agree to the dispute resolution provisions
                                        below.</span></p>
                                    <p class="d1"><span class="d5">Commencement of Arbitration</span><span class="d0">. You and Company agree to
                                        commence any arbitration proceeding within 1 year after the Claim arises (including the mandatory
                                        pre-dispute procedures outlined above) and that any proceeding commenced after 1 year shall be barred.
                                        Arbitration Location. For your convenience, the arbitration may be conducted in the federal district where
                                        you reside. It may be held by telephone or through written submissions if both you and Company agree.</span>
                                    </p>
                                    <p class="d1"><span class="d5">Sponsoring Organization, Rules and the Arbitrator</span><span class="d0">. You agree
                                        that any Claims shall be resolved by submitting the dispute to final and binding confidential arbitration
                                        before a single arbitrator who is a retired judge or an experienced attorney with experience in the
                                        subject(s) of the Claim. The arbitrator shall be chosen from JAMS Comprehensive Arbitration Rules and
                                        Procedures and the arbitration rules of the selected tribunal shall apply, which can be obtained by calling
                                        the selected tribunal.</span></p>
                                    <p class="d1"><span class="d0">The arbitrator shall have the exclusive and sole authority to resolve any dispute
                                        relating to the interpretation, construction, validity, applicability, or enforceability of these Terms and
                                        Conditions and this arbitration provision. The arbitrator shall have the exclusive and sole authority to
                                        determine whether this arbitration agreement can be enforced against a non-signatory to this agreement and
                                        whether a non-signatory to this agreement can enforce this provision against you or Company.</span></p>
                                    <p class="d1"><span class="d5">Arbitration Fees</span><span class="d0">. Company shall pay for all filing,
                                        administrative, and arbitrator fees for an arbitration initiated by either party. The parties shall each pay
                                        their own additional fees, costs, and expenses, including, but not limited to, those for any attorneys,
                                        experts, documents, and witnesses.</span></p>
                                    <p class="d1"><span class="d5">Arbitration Award</span><span class="d0">. The arbitrator shall follow substantive
                                        law and may order any relief if permitted by law. The arbitrator may award any form of individual relief,
                                        including injunctions and punitive damages, so long as they are in accordance with applicable law. The
                                        arbitrator may award costs or fees to a prevailing party, but only if the law expressly allows it. Although
                                        Company may have a right to an award of attorneys&rsquo; fees and expenses under some laws if it prevails,
                                        Company agrees that it will not seek such an award, unless your Claims are determined by the arbitrator to
                                        be frivolous. Nothing herein shall be construed to limit the arbitrator&rsquo;s ability to award remedies
                                        provided by applicable law. Any award rendered shall include a written opinion and shall be final, subject
                                        to appeal under the FAA.</span></p>
                                    <p class="d1"><span class="d5">Enforceability</span><span class="d0">. This provision survives your relationship
                                        with Company, bankruptcy, assignment or transfer. If the class action waiver is deemed unenforceable (i.e.,
                                        unenforceability would allow arbitration to proceed as a class or representative action), then this entire
                                        arbitration provision shall be rendered null and void and shall not apply. If a portion of this arbitration
                                        provision (other than the class action waiver) is deemed unenforceable, the remaining portions of this
                                        arbitration provision shall remain in full force and effect.</span></p>
                                    <p class="d1"><span class="d5">Miscellaneous</span><span class="d0">. Failure or any delay in enforcing this
                                        arbitration provision in connection with any particular Claims will not constitute a waiver of any rights to
                                        require arbitration at a later time or in connection with any other Claims. This provision is the entire
                                        arbitration agreement between you and Company and shall not be modified except in writing by Company.</span>
                                    </p>
                                    <p class="d1"><span class="d5">Amendments</span><span class="d0">. Company reserves the right to amend this
                                        arbitration provision at any time. Your continued use of any Company Website, or use or attempted use of a
                                        Company service, is affirmation of your consent to such changes. Should the changes to this arbitration
                                        provision be material, Company will provide you notice and an opportunity to opt-out.</span></p>
                                    <p class="d1"><span class="d0">YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION PROVISION WITHIN 30 DAYS FROM THE
                                        DATE OF, USE, OR ATTEMPTED USE OF A COMPANY SERVICE ( WHICHEVER COMES FIRST) BY WRITING TO PIXELCRAFT LLC,
                                        16192 COASTAL HWY, LEWES, SUSSEX, DE, 19958. UNTIMELY OPT-OUTS WILL NOT BE VALID AND YOU MUST THEN PURSUE
                                        YOUR CLAIM THROUGH ARBITRATION PURSUANT TO THESE TERMS.</span></p>
                                    <h2 class="d3" id="h.xr2nd6hq5k43"><span class="d2">14. Exclusive Venue for Other Controversies</span></h2>
                                    <p class="d1"><span class="d0">You agree that any controversy excluded from the dispute resolution procedure and
                                        class action waiver provisions in this Agreement (other than an individual action filed in small claims
                                        court) shall be filed only in the Supreme Court in New York County, New York, or the United States District
                                        Court for the Southern District of New York, and each party hereby irrevocably and unconditionally consents
                                        and submits to the exclusive jurisdiction of such courts for any such controversy.</span></p>
                                    <h2 class="d3" id="h.4gum9jiogwtc"><span class="d2">15. Remedies for Company</span></h2>
                                    <p class="d1"><span class="d0">In order to avoid irreparable injury to Company, in the event of any breach or
                                        threatened breach by you of the provisions of this Agreement, we shall be entitled to seek an injunction
                                        and/or other equitable relief restraining such breach. Nothing in this Agreement shall be construed as
                                        prohibiting Company from pursuing any other remedies available to it for such breach or threatened breach,
                                        including the recovery of monetary damages from you.</span></p>
                                    <h2 class="d3" id="h.6vx7ttd2j3m8"><span class="d2">16. Modifications to the Agreement</span></h2>
                                    <p class="d1"><span class="d0">Company may make changes to these Terms and Conditions, from time to time, in its
                                        sole discretion, by updating this posting on the Website without notice to you. Your continued use of the
                                        Website following the posting of a new version of the Terms and Conditions constitutes your acceptance of
                                        any such changes. Accordingly, whenever you visit the Website, check to see if a new version has been
                                        posted.</span></p>
                                    <h2 class="d3" id="h.x3u3ufmqmpre"><span class="d2">17. Trademark Notices</span></h2>
                                    <p class="d1"><span class="d0">Company is a trademark of PixelCraft LLC. All other trademarks and service marks
                                        displayed on the Website are the property of Company or their respective owners. You may not use or display
                                        any trademarks or service marks owned by Company without Company&rsquo;s prior written consent. You may not
                                        use or display any other trademarks or service marks displayed on the Website without the permission of
                                        their owners.</span></p>
                                    <h2 class="d3" id="h.9gwtbz7fytd7"><span class="d2">18. Copyright Policy</span></h2>
                                    <p class="d1"><span class="d0">It is Company&rsquo;s policy to respect the copyright and intellectual property
                                        rights of others. Company may remove content that appears to infringe the copyright or other intellectual
                                        property rights of others. In addition, Company may terminate access by users who appear to infringe the
                                        copyright or other intellectual property rights of others. Further, Company complies with the Digital
                                        Millennium Copyright Act.</span></p>
                                    <p class="d1"><span class="d0">If you believe in good faith that your work has been copied in a way that constitutes
                                        copyright infringement, please provide Company&rsquo;s Copyright Agent the following information:</span></p>
                                    <ul class="d7 lst-kix_t56a51rxgwuq-0 start">
                                        <li class="d1 d4 li-bullet-0"><span class="d0">i. An electronic or physical signature of the person authorized
                                            to act on behalf of the owner of the copyright interest.</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">ii. A description of the copyrighted work that you claim has been
                                            infringed.</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">iii. A description of where the material that you claim is
                                            infringing is located on the Website.</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">iv. Your address, telephone number, and e-mail address.</span>
                                        </li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">v. A statement by you that you have a good faith belief that the
                                            disputed use is not authorized by the copyright owner, its agent, or the law.</span></li>
                                        <li class="d1 d4 li-bullet-0"><span class="d0">vi. A statement by you, made under penalty of perjury (e.g.,
                                            notarized affidavit), that the above information in your notice is accurate and that you are the
                                            copyright owner or authorized to act on the copyright owner&rsquo;s behalf.</span></li>
                                    </ul>
                                    <p class="d1"><span class="d0">Please direct inquiries regarding infringement issues by email to
                                        policy@pixelcraftllc.com</span></p>
                                    <h2 class="d3" id="h.qwd8q4ye9eof"><span class="d2">19. Other Important Terms</span></h2>
                                    <p class="d1"><span class="d0">Company may assign, transfer, or sub-contract any of our rights or obligations under
                                        these Terms and Conditions to any third party at our discretion. Any representations, warranties, and
                                        indemnification obligations made or undertaken by you will survive. No delay by Company in exercising any
                                        right or remedy under these Terms and Conditions shall operate as a waiver of that right or remedy or shall
                                        affect Company&rsquo;s ability to subsequently exercise that right or remedy. Any waiver must be agreed to
                                        by Company in writing. These Terms and Conditions supersede any other terms previously published by us and
                                        any other representations or statements made by us to you, whether oral, written, or otherwise.</span></p>
                                    <p class="d1"><span class="d6">Policy Last Revised: Oct 5, 2023</span></p>
                                    <p class="d12"><span class="d8"></span></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default Terms;
