import React from 'react';
import "./Footer.css";
import Flogo from "../../assets/SS_Main.svg";
import { footerContent } from '../../content';

const Footer = ({ showLogo }) => {
  return (
    <footer>
      <div className="ftop">
        {showLogo && (
          <div className="logo">
            <img src={Flogo} alt="Survey Reel Logo" />
          </div>
        )}
      </div>
      <div className="footerCopywrite"><p dangerouslySetInnerHTML={{__html:footerContent.copy}} /></div>
      <div className="notice"><p dangerouslySetInnerHTML={{__html:footerContent.notice}} /></div>

    </footer>
  );
};

Footer.defaultProps = {
  showLogo: false
};

export default Footer;
