import React from 'react';
import "./Logo.css";
import Flogo from "../../assets/SS_Main.svg";

const Logo = () => {
    return (
        <div className="ftop">
            <div className="logo">
                <a href='/'><img src={Flogo} alt="SurveyReel" /></a>
            </div>
        </div>);
}

export default Logo;