import React, { useState, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import FormDataContext from "./contexts/FormDataContext";
import router from "./router";
import { getAuth } from "firebase/auth";
import { firebaseSignInAnonymously } from "./components/FirebaseService";
import ReactGA from 'react-ga4';

const App = () => {
  const [formData, setFormData] = useState({});
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setGoogleAnalyticsUserId(user.uid);
      } else {
        firebaseSignInAnonymously();
      }
    });
    return () => unsubscribe();
  }, []);

  const setGoogleAnalyticsUserId = (uid) => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize('G-KZ2JHFP015');
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({ userId: uid });
  };

  return (
    <FormDataContext.Provider value={{ formData, setFormData }}>
      <RouterProvider router={router} />
    </FormDataContext.Provider>
  );
};

export default App;