import "./About.css";
import Info1 from "../../assets/whoweare.webp";
import Info2 from "../../assets/dataprotection.webp";
import { aboutContent } from "../../content";
import ArticleList from "../ArticleList/ArticleList";

const About = () => {
  return (
    <section id="about">
      <div className="aboutWrapper">
        <div className="aboutTop">
          <div className="aboutLeft">
            <h2>{aboutContent.whoWeAreTitle}</h2>
            <p>
              {aboutContent.whoWeAre}
            </p>
            <br />
            <p dangerouslySetInnerHTML={{ __html: aboutContent.questions }}></p>
          </div>
          <div className="aboutRight">
            <img src={Info1} alt="" />
          </div>
        </div>
        <div className="aboutBottom" id="about">
          <div className="aboutLeft">
            <img src={Info2} alt="" />
          </div>
          <div className="aboutRight">
            <h2>{aboutContent.rightTitle}</h2>
            {/* <p> */}
              {/* {aboutContent.dataProtection} */}
                          <ArticleList />

            {/* </p> */}
            {/* <br /> */}
            {/* <p dangerouslySetInnerHTML={{ __html: aboutContent.optOut }}></p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
