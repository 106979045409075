import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ExternalRedirect = ({ to }) => {
  const { search } = useLocation();

  useEffect(() => {
    const destinationUrl = new URL(to);
    const currentParams = new URLSearchParams(search);
    const destinationParams = new URLSearchParams(destinationUrl.search);

    for (const [key, value] of currentParams) {
      destinationParams.set(key, value);
    }

    destinationUrl.search = destinationParams.toString();

    window.location.href = destinationUrl.toString();
  }, [to, search]);

  return null;
};

export default ExternalRedirect;
